<div class="chart-block" *ngIf="toggle">
  <span class="poi-disclaimer" *ngIf="chart.disclaimer">{{chart.disclaimer}}</span>

  <mat-slide-toggle

    class="menu-toggle"
    [formControl]="toggle"
    [disabled]="disabled"
    [ngClass]="disabled ? 'disabled' : ''"
    [attr.data-cy]="'toggle-' + chart.e2eName"
  >{{chart.toggleTitle}}

    <app-feature-tooltip
      *ngIf="chart.tooltip || disabled"
      [headerText]="chart.tooltip ? chart.tooltip.headerText : ''"
      [isFeatureInaccessibleType]="disabled"
      [tooltipText]="chart.tooltip ? chart.tooltip.text : ''"></app-feature-tooltip>
  </mat-slide-toggle>


  <app-chart-year-selection
    *ngIf="chart.accessibleYears"
    [isChartVisible]="featureStatus!.isSelected && toggle && toggle!.value"
    [selectedYear]="$any(featureStatus)![chart.yearVariable!]"
    [accessibleYears]="chart.accessibleYears"
    [usedFor]="chart.yearVariable!"
    (selectedYearChange)="onSelectedYearChange($event)"
  ></app-chart-year-selection>

  <div class="chart"
       *ngIf="toggle && featureStatus!.isSelected && toggle.value && $any(featureStatus)[chart.containsChartDataVariable]">
    <app-plotly-chart *ngIf="toggle.value"
                      [naturalId]="featureStatus!.naturalId"
                      [selectedYear]="$any(featureStatus)![chart.yearVariable!]"
                      [chart]="chart.plotlyChart || chart.chartsByYears![$any(featureStatus)[$any(chart.yearVariable)]]"
                      [chartStyle]="chart.chartStyle || defaultChartStyle"
                      [chartName]="chart.chartName"
                      [e2eName]="chart.e2eName"
    ></app-plotly-chart>
  </div>
  <app-chart-warning
    [selectedYear]="wasYearSelected ? $any(featureStatus)![chart.yearVariable!] : undefined"
    [selected]="featureStatus.isSelected"
    [containsInfo]="$any(featureStatus)[chart.containsChartDataVariable]"
    [isToggleActive]="toggle && toggle.value"
    [chartName]="chart.chartName"
  ></app-chart-warning>
</div>
