export const POPULATION = 'population';
export const AREA = "AREA"
export const MEDIAN_INCOME = 'median_income';
export const PEOPLE_PER_MI2 = 'people_per_mi2';
export const HOUSEHOLDS = 'households';
export const TARGET_AUDIENCE = 'targetAudience'
export const ROAD_DENSITY = 'road_density_per_mi2'
export const GINI_INDEX = 'gini_index';
export const POPULATION_GROWTH_TREND = 'POPULATION_GROWTH_TREND'
export const MEDIAN_HOUSEHOLD_INCOME_TREND = 'MEDIAN_HOUSEHOLD_INCOME_TREND'
export const MEDIAN_HOUSING_SALE_COST_TREND = 'MEDIAN_HOUSING_SALE_COST_TREND'
export const MEDIAN_GROSS_RENT_TREND = 'MEDIAN_GROSS_RENT_TREND'
export const CUMULATIVE_TRAFFIC_INTENSITY = 'CUMULATIVE_TRAFFIC_INTENSITY'
export const MEDIAN_HOUSING_SALE_COST = 'MEDIAN_HOUSING_SALE_COST'
export const MEDIAN_GROSS_RENT = 'MEDIAN_GROSS_RENT'
export const WEALTH_INDEX_CONST = 'wealth_index'
export const WEALTH_INDEX_BY_COUNTRY= 'wealth_index_by_country'
export const WEALTH_INDEX_BY_STATE= 'wealth_index_by_state'
export const HEALTH_INDEX_CONST = 'health_index'
export const HEALTH_INDEX_BY_COUNTRY= 'health_index_by_country'
export const HEALTH_INDEX_BY_STATE= 'health_index_by_state'
export const WISE_INDEX_CONST = 'wise_index'
export const WISE_INDEX_BY_COUNTRY= 'wise_index_by_country'
export const WISE_INDEX_BY_STATE= 'wise_index_by_state'
export const COMBINED_HWW_CONST = 'franklin_index'
export const COMBINED_HWW_BY_COUNTRY = 'franklin_index_by_country'
export const COMBINED_HWW_BY_STATE = 'franklin_index_by_state'
export const LIFE_EXPECTANCY = 'LIFE_EXPECTANCY'
export const CONVENIENCE_STORES = 'CONVENIENCE_STORES'
export const DRUGSTORES = 'DRUGSTORES'
export const RETAIL = 'RETAIL'
export const BEAUTY = 'BEAUTY'
export const UNEMPLOYMENT_RATIO = 'UNEMPLOYMENT_RATIO'
export const UNINSURED_RATIO = 'UNINSURED_RATIO'
export const HOME_OWNERSHIP_RATIO = 'HOME_OWNERSHIP_RATIO'
export const CRIME_RATE = 'CRIME_RATE'

export const SMOKING_RATE = 'SMOKERS_FRACTION'
export const BINGE_DRINKING_RATE = 'DRINKERS_FRACTION'
export const OBESITY_RATE = 'OBESITY_FRACTION'
export const DIABETES_PREVALENCE = 'DIABETES_FRACTION'
export const CANCER_PREVALENCE = 'CANCER_FRACTION'
export const HEART_DISEASES_PREVALENCE = 'HEART_DISEASES_FRACTION'
export const AIR_POLLUTION = 'AIR_POLLUTION'

export const PATIENT_TO_PHYSICIAN_RATIO = 'PRIMARY_CARE_PHYSICIANS_RATIO'
export const PATIENT_TO_DENTIST_RATIO = 'DENTISTS_RATIO'
export const PATIENT_TO_THERAPIST_RATIO = 'MENTAL_HEALTH_PROVIDERS_RATIO'

export const SPECIAL_HEALTHCARE_NEEDS = 'SPECIAL_HEALTHCARE_NEEDS'

export const AGE_SEX_DISTRIBUTION_CHART = 'AGE_SEX_DISTRIBUTION_CHART'
export const EMPLOYMENT_CHART = 'EMPLOYMENT_CHART'
export const VACANCY_CHART = 'VACANCY_CHART'
export const GROSS_RENT_DISTRIBUTION_CHART = 'GROSS_RENT_DISTRIBUTION_CHART'
export const HOUSING_SELL_PRICE_DISTRIBUTION_CHART = 'HOUSING_SELL_PRICE_DISTRIBUTION_CHART'
export const TENURE_CHART = 'TENURE_CHART'
export const NUMBER_OF_ROOMS_CHART = 'NUMBER_OF_ROOMS_CHART'
export const EDUCATION_ATTAINMENT_CHART = 'EDUCATION_ATTAINMENT_CHART'
export const INSURANCE_DISTRIBUTION_CHART = 'INSURANCE_DISTRIBUTION_CHART'
export const POPULATION_TREND_CHART = 'POPULATION_TREND_CHART'
export const RACE_DISTRIBUTION_CHART = 'RACE_DISTRIBUTION_CHART'
export const INCOME_DISTRIBUTION_CHART = 'INCOME_DISTRIBUTION_CHART'
export const ROAD_DENSITY_CHART = 'ROAD_DENSITY_CHART'
export const TRAFFIC_INTENSITY_CHART = 'TRAFFIC_INTENSITY_CHART'
export const COMPETITORS_POI_CHART = 'COMPETITORS_CHART'
export const BEAUTY_POI_CHART = 'BEAUTY_POI_CHART'
export const FAMILY_POI_CHART = 'FAMILY_POI_CHART'
export const RETIREMENT_POI_CHART = 'RETIREMENT_POI_CHART'
export const CONVENIENCE_POI_CHART = 'CONVENIENCE_POI_CHART'
export const FINANCIAL_SERVICES_POI_CHART = 'FINANCIAL_SERVICES_POI_CHART'
export const OUTDOOR_POI_CHART = 'OUTDOOR_POI_CHART'
export const HEALTHCARE_POI_CHART = 'HEALTHCARE_POI_CHART'
export const TARGET_AUDIENCE_CHART = 'TARGET_AUDIENCE_CHART'
export const SCHOOLS_POI = 'SCHOOLS_POI'
export const COMMUNITY_CRIME_RATE = 'INDIVIDUAL_CRIMES'
export const BUSINESS_CRIME_RATE = 'BUSINESS_CRIMES'
export const GENDER_PAY_GAP = 'GENDER_PAY_GAP'
export const UNHEALTHY_BEHAVIOUR_INDEX = 'UNHEALTHY_BEHAVIOUR_INDEX'
export const SPECIAL_HEALTH_CARE_NEEDS_INDEX = 'SPECIAL_HEALTH_CARE_NEEDS_INDEX'

export const PRESIDENTIAL_ELECTIONS_RESULTS2020 = 'PRESIDENTIAL_ELECTIONS_RESULTS2020'
export const PRESIDENTIAL_ELECTIONS_RESULTS2024 = 'PRESIDENTIAL_ELECTIONS_RESULTS2024'
// +population if Trump is winner, -population if Biden is
export const BIDEN_VS_TRUMP_2020_POPULATION = 'BIDEN_VS_TRUMP_2020_POPULATION'
export const HARRIS_VS_TRUMP_2024_POPULATION = 'HARRIS_VS_TRUMP_2024_POPULATION'
export const HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS = 'HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS'
export const SENATE_ELECTION_2022_RESULTS = 'SENATE_ELECTION_2022_RESULTS'
export const BACHELORS_DEGREE_OR_HIGHER_FRACTION = 'BACHELORS_DEGREE_OR_HIGHER_FRACTION'
export const STRUCTURE_BUILT_MEDIAN_YEAR = 'STRUCTURE_BUILT_MEDIAN_YEAR'
export const MEDIAN_TENURE = 'MEDIAN_TENURE'

export const voteDataFeatures = [
PRESIDENTIAL_ELECTIONS_RESULTS2020,
PRESIDENTIAL_ELECTIONS_RESULTS2024,
BIDEN_VS_TRUMP_2020_POPULATION,
HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  //This two are colored by colorMapByCells() so we dont need them in setVotersData()
// HARRIS_VS_TRUMP_2024_POPULATION,
// SENATE_ELECTION_2022_RESULTS
]

export const cdcBulkFeatures = [
  SMOKING_RATE,
  BINGE_DRINKING_RATE,
  OBESITY_RATE,
  DIABETES_PREVALENCE,
  CANCER_PREVALENCE,
  HEART_DISEASES_PREVALENCE
]

export const chrBulkFeatures = [
  PATIENT_TO_PHYSICIAN_RATIO,
  PATIENT_TO_DENTIST_RATIO,
  PATIENT_TO_THERAPIST_RATIO,
  AIR_POLLUTION
]

export const crimeRateByVictimsTypeBulkFeatures = [
  COMMUNITY_CRIME_RATE,
  BUSINESS_CRIME_RATE
]

export const crimeRatesList = [
  COMMUNITY_CRIME_RATE,
  BUSINESS_CRIME_RATE,
  CRIME_RATE
]

export const availableInBulkFeatures = [
  ...cdcBulkFeatures,
  ...chrBulkFeatures,
  ...crimeRateByVictimsTypeBulkFeatures,
  LIFE_EXPECTANCY,
  POPULATION_GROWTH_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_GROSS_RENT_TREND,
  CUMULATIVE_TRAFFIC_INTENSITY,
  MEDIAN_HOUSING_SALE_COST,
  MEDIAN_GROSS_RENT,
  UNINSURED_RATIO,
  UNEMPLOYMENT_RATIO,
  HOME_OWNERSHIP_RATIO,
  CRIME_RATE,
  GENDER_PAY_GAP,
  UNHEALTHY_BEHAVIOUR_INDEX,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  STRUCTURE_BUILT_MEDIAN_YEAR,
  MEDIAN_TENURE

]

export const targetAudienceIndustries = [
  CONVENIENCE_STORES,
  DRUGSTORES,
  RETAIL,
  BEAUTY
]

export const trendLayers: string[] = [
  POPULATION_GROWTH_TREND,
  MEDIAN_GROSS_RENT_TREND,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
]

export const ratioLayers = [
  UNINSURED_RATIO,
  UNEMPLOYMENT_RATIO,
  HOME_OWNERSHIP_RATIO
]

export const hwwFeatures = [
  WEALTH_INDEX_BY_COUNTRY,
  WEALTH_INDEX_BY_STATE,
  WEALTH_INDEX_CONST,
  HEALTH_INDEX_BY_COUNTRY,
  HEALTH_INDEX_BY_STATE,
  HEALTH_INDEX_CONST,
  WISE_INDEX_BY_COUNTRY,
  WISE_INDEX_BY_STATE,
  WISE_INDEX_CONST,
  COMBINED_HWW_BY_COUNTRY,
  COMBINED_HWW_BY_STATE,
  COMBINED_HWW_CONST
]

// Meaning features which values were written into feature.state to then read by mapbox
//TODO: UPDATE ON EACH STATE DEPENDENT FEATURE ADDING
export const writtenInStateFeatures = [
  ...targetAudienceIndustries,
  ...trendLayers,
  ...cdcBulkFeatures,
  ...chrBulkFeatures,
  ...crimeRateByVictimsTypeBulkFeatures,
  ...voteDataFeatures,
  CUMULATIVE_TRAFFIC_INTENSITY,
  LIFE_EXPECTANCY,
  MEDIAN_HOUSING_SALE_COST,
  MEDIAN_GROSS_RENT,
  POPULATION_GROWTH_TREND,
  COMBINED_HWW_BY_STATE,
  COMBINED_HWW_BY_COUNTRY,
  UNINSURED_RATIO,
  UNEMPLOYMENT_RATIO,
  HOME_OWNERSHIP_RATIO,
  CRIME_RATE,
  AIR_POLLUTION,
  GENDER_PAY_GAP,
  UNHEALTHY_BEHAVIOUR_INDEX,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  STRUCTURE_BUILT_MEDIAN_YEAR,
  MEDIAN_TENURE
]


export const allFeatureNames = [
  ...cdcBulkFeatures,
  ...chrBulkFeatures,
  ...crimeRateByVictimsTypeBulkFeatures,
  POPULATION,
  MEDIAN_INCOME,
  PEOPLE_PER_MI2,
  HOUSEHOLDS,
  TARGET_AUDIENCE,
  ROAD_DENSITY,
  GINI_INDEX,
  POPULATION_GROWTH_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_GROSS_RENT_TREND,
  CUMULATIVE_TRAFFIC_INTENSITY,
  MEDIAN_HOUSING_SALE_COST,
  MEDIAN_GROSS_RENT,
  WEALTH_INDEX_BY_COUNTRY,
  WEALTH_INDEX_BY_STATE,
  HEALTH_INDEX_BY_COUNTRY,
  HEALTH_INDEX_BY_STATE,
  WISE_INDEX_BY_COUNTRY,
  WISE_INDEX_BY_STATE,
  COMBINED_HWW_BY_COUNTRY,
  COMBINED_HWW_BY_STATE,
  LIFE_EXPECTANCY,
  CONVENIENCE_STORES,
  BEAUTY,
  DRUGSTORES,
  RETAIL,
  UNINSURED_RATIO,
  UNEMPLOYMENT_RATIO,
  HOME_OWNERSHIP_RATIO,
  SCHOOLS_POI,
  CRIME_RATE,
  GENDER_PAY_GAP,
  UNHEALTHY_BEHAVIOUR_INDEX,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  STRUCTURE_BUILT_MEDIAN_YEAR,
  MEDIAN_TENURE
]

export const availableFeatures = [
  'AREA',
  'POPULATION',
  'POPULATION_DENSITY_MILES',
  'MEDIAN_INCOME',
  'HOUSEHOLDS',
  'WEALTH_INDEX',
  'HEALTH_INDEX',
  'WISE_INDEX',
  'ROAD_LENGTH',
  'POINTS_OF_INTEREST',
  'AGE_SEX',
  'POPULATION_BY_YEARS',
  'INCOME_DISTRIBUTION',
  'EDUCATION_ATTAINMENT',
  'HOUSEHOLDS_INCOME',
  'HOUSEHOLD_INCOME_MEDIAN_BY_YEARS',
  'INSURANCE',
  'EMPLOYMENT',
  'HOME_OWNERSHIP',
  'HOUSING_SALE_COST',
  'HOUSING_SALE_COST_MEDIANS_BY_YEARS',
  'HOUSING_AGGREGATED_PRICE',
  'HOUSING_ROOMS',
  'HOUSING_GROSS_RENT',
  'HOUSING_GROSS_RENT_MEDIANS_BY_YEARS',
  'LIFE_EXPECTANCY',
  OBESITY_RATE,
  'SMOKERS_FRACTION',
  'DRINKERS_FRACTION',
  'SEDENTARY_FRACTION',
  'DIABETES_FRACTION',
  'CANCER_FRACTION',
  'HEART_DISEASES_FRACTION',
  'BAD_HEALTH_SELF_RATED_FRACTION',
  'PRIMARY_CARE_PHYSICIANS',
  'DENTISTS',
  'MENTAL_HEALTH_PROVIDERS',
  'AIR_POLLUTION',
  'TRAFFIC_INTENSITY',
  'TARGET_AUDIENCE',
  'GINI_INDEX',
  GENDER_PAY_GAP,
  MEDIAN_GROSS_RENT,
  MEDIAN_HOUSING_SALE_COST,
  HOME_OWNERSHIP_RATIO,
  UNINSURED_RATIO,
  MEDIAN_GROSS_RENT_TREND,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
  UNEMPLOYMENT_RATIO,
  POPULATION_GROWTH_TREND,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  UNHEALTHY_BEHAVIOUR_INDEX,
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  STRUCTURE_BUILT_MEDIAN_YEAR,
  MEDIAN_TENURE
]

export const featuresToMultiplyBy100 = [
  OBESITY_RATE,
  SMOKING_RATE,
  UNINSURED_RATIO,
  GENDER_PAY_GAP,
  HOME_OWNERSHIP_RATIO,
  DIABETES_PREVALENCE,
  BINGE_DRINKING_RATE,
  HEART_DISEASES_PREVALENCE,
  UNEMPLOYMENT_RATIO,
  CANCER_PREVALENCE,
]

export const featuresToCompareToNationalPopup = [
  MEDIAN_INCOME,
  POPULATION_GROWTH_TREND,
  LIFE_EXPECTANCY,
  OBESITY_RATE,
  SMOKING_RATE,
  BINGE_DRINKING_RATE,
  DIABETES_PREVALENCE,
  CANCER_PREVALENCE,
  HEART_DISEASES_PREVALENCE,
  MEDIAN_GROSS_RENT_TREND,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
  UNEMPLOYMENT_RATIO,
  AIR_POLLUTION,
  GINI_INDEX,
  GENDER_PAY_GAP,
  MEDIAN_GROSS_RENT,
  MEDIAN_HOUSING_SALE_COST,
  HOME_OWNERSHIP_RATIO,
  UNINSURED_RATIO,
  UNHEALTHY_BEHAVIOUR_INDEX,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  // FIXME: backend bug `No crime rate data for year 2022 and type BUSINESS`
  // CRIME_RATE,
  // COMMUNITY_CRIME_RATE,
  // BUSINESS_CRIME_RATE
  // FIXME:backend bug `Can't cast null to Double from ChrRatioFormula`
  // PATIENT_TO_DENTIST_RATIO,
  // PATIENT_TO_PHYSICIAN_RATIO,
  // PATIENT_TO_THERAPIST_RATIO
].map(feature => feature.toUpperCase());


//FIXME feature duplication due to differences between tile server and backend naming different
export const arbitraryCellPopupFeatures = [
  "AREA",
  "POPULATION",
  "POPULATION_DENSITY_MILES",
  "MEDIAN_INCOME",
  "HOUSEHOLDS",
  "WEALTH_INDEX",
  "HEALTH_INDEX",
  "WISE_INDEX",
  "LIFE_EXPECTANCY",
  OBESITY_RATE,
  "SMOKERS_FRACTION",
  "DRINKERS_FRACTION",
  // "SEDENTARY_FRACTION",
  "DIABETES_FRACTION",
  "CANCER_FRACTION",
  "HEART_DISEASES_FRACTION",
  "BAD_HEALTH_SELF_RATED_FRACTION",
  "PRIMARY_CARE_PHYSICIANS",
  "DENTISTS",
  "MENTAL_HEALTH_PROVIDERS",
  "AIR_POLLUTION",
  // "TARGET_AUDIENCE",
  "GINI_INDEX",
]

