import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UserTip } from '../map.component';
import {
  CHARTS,
  LENSES,
  LEVEL_SELECTION,
  MAPS,
  RADIUS_SELECTION,
  SEARCH,
} from './hint-utility';
import { BreakpointObserverService } from '../../shared/services/breakpoint-observer.service';

export const GUIDE_COMPLETED = 'GUIDE_COMPLETED';

@Component({
  selector: 'app-popup-hint',
  templateUrl: './popup-hint.component.html',
  styleUrls: ['./popup-hint.component.scss'],
})
export class PopupHintComponent implements OnInit, AfterViewInit {
  @Output() closePopup = new EventEmitter();

  public title!: string;
  public cssClass!: string;
  public textContent!: string;
  public closable!: boolean;

  constructor(
    public dialogRef: MatDialogRef<PopupHintComponent>,
    private el: ElementRef,
    private renderer: Renderer2,
    private breakpointObserverService: BreakpointObserverService,
    @Inject(MAT_DIALOG_DATA) public data: UserTip
  ) {
    this.title = data.title;
    this.cssClass = data.cssClass;
    this.textContent = data.textContent;
    this.closable = data.closable;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setArrowLocation(this.title);
  }

  private setArrowLocation(title: string): void {
    const container = this.el.nativeElement.querySelector('.popup-container');
    const arrowPointer = this.el.nativeElement.querySelector('.arrow-pointer');

    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    const rect: DOMRect = container.getBoundingClientRect();

    if (this.breakpointObserverService.isMobile.getValue()) {
      if (title === SEARCH) {
        const newTop = viewportHeight - rect.top;
        this.renderer.setStyle(arrowPointer, 'bottom', `${newTop - 6}px`);
        this.renderer.setStyle(arrowPointer, 'left', `${45}px`);
        this.renderer.setStyle(arrowPointer, 'transform', 'rotate(270deg)')
      } else if (title === MAPS || title === LEVEL_SELECTION) {
        const newBottom = viewportHeight - rect.top;
        this.renderer.setStyle(arrowPointer, 'bottom', `${newBottom - 10}px`);
        this.renderer.setStyle(
          arrowPointer,
          'left',
          `${(viewportWidth - 20) / 2}px`
        );
      }
    } else if (!this.breakpointObserverService.isMobile.getValue()) {
      if (
        title === SEARCH ||
        title === RADIUS_SELECTION ||
        title === LEVEL_SELECTION
      ) {
        this.renderer.setStyle(arrowPointer, 'top', `${rect.top - 25}px`);
        this.renderer.setStyle(arrowPointer, 'left', `${rect.left + 20}px`);
      } else if (title === MAPS || title === CHARTS || title === LENSES) {
        const viewportWidth = window.innerWidth;
        const newRight = viewportWidth - (rect.right + 19);
        this.renderer.setStyle(arrowPointer, 'left', `auto`);
        this.renderer.setStyle(arrowPointer, 'top', `${rect.top + 20}px`);
        this.renderer.setStyle(arrowPointer, 'right', `${newRight}px`);
      }
    }
  }

  public handleClose(): void {
    this.dialogRef.close();
  }

  public handleDontShowAgain(): void {
    localStorage.setItem(GUIDE_COMPLETED, 'true');
    this.dialogRef.close();
  }
}
