import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { BreakpointObserverService } from '../../../shared/services/breakpoint-observer.service';
import { SubscriptionActivatedComponent } from './subscription-activated/subscription-activated.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionPlans } from '../user.model';
import { AuthenticationService } from '../../authentication.service';

export const TRIGGER_REFRESH_USER_DATA = 'TRIGGER_REFRESH_USER_DATA';

@Component({
  selector: 'app-subscriptions-menu',
  templateUrl: './subscriptions-menu.component.html',
  styleUrls: ['./subscriptions-menu.component.scss',
    '../../../app.component.scss']
})
export class SubscriptionsMenuComponent implements OnInit, AfterViewInit {
  @ViewChildren('planElement', {read: ElementRef}) planElements!: QueryList<ElementRef>;

  // Filter out plans that are not meant to be user-available
  public readonly plans = Object.values(SubscriptionPlans.description).filter(el => !el.notAvailableForUser)

  public activePlanIndex: number = 1
  public urlSelectedPlanIndex: number | undefined
  public isPeriodMonthly: boolean = false

  public userSubscription: SubscriptionPlans | null = null;

  constructor(public breakpointObserverService: BreakpointObserverService,
              private dialog: MatDialog,
              private authService: AuthenticationService,
              private activatedRoute: ActivatedRoute,) {
    authService.userPersonalData.subscribe(user => {
      if (user !== null) {
        this.userSubscription = user.subscription?.plan ?? null;
      }
    });

  }

  ngOnInit(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === TRIGGER_REFRESH_USER_DATA) {
        setTimeout(() => {
          this.authService.refreshUserInfo()
        }, 1500)
      }
    })

    const paymentSuccess = this.activatedRoute.snapshot.queryParamMap.get('payment-success');

    if (paymentSuccess === 'true') {
      this.dialog.open(SubscriptionActivatedComponent, {
        maxWidth: '624px',
        maxHeight: '364px',
      });
    }

  }

  ngAfterViewInit() {
      this.parsePlanFromQuery()
  }

  public parsePlanFromQuery(): void {
    const plan = this.activatedRoute.snapshot.queryParamMap.get('plan');
    this.isPeriodMonthly = this.activatedRoute.snapshot.queryParamMap.get('yearly') === 'false';

    this.urlSelectedPlanIndex = this.plans.findIndex(el => el.requestKey === plan);

    if (this.urlSelectedPlanIndex >= 0 && this.planElements.length > this.urlSelectedPlanIndex) {
      this.planElements.toArray()[this.urlSelectedPlanIndex].nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public onToggleOuterButton(index: number) {
    this.activePlanIndex = index;
  }
}
