import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { JsonResponse } from '../api/backend-config';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private http: HttpClient) {}

  public requestExperimentalLLm(
    geoId: string,
    level: string
  ): Observable<JsonResponse<string>> {
    return this.http.get<JsonResponse<string>>(
      `${environment.apiUrl}ai/experiment?cellGeoId=${geoId}&cellType=${level}`
    );
  }
}
