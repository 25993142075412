import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterIndividualComponent } from './sign-up/register-individual/register-individual.component';
import { MySearchesComponent } from './user/my-searches/my-searches.component';
import { AccountSettingsFormComponent } from './user/account-settings-form/account-settings-form.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SubscriptionsMenuComponent } from './user/subscriptions-menu/subscriptions-menu.component';
import { PlanWithDescriptionComponent } from './user/subscriptions-menu/plan-with-description/plan-with-description.component';
import { RegistrationSuccessWindowComponent } from './sign-up/register-individual/registration-success-window/registration-success-window.component';
import { SubscriptionActivatedComponent } from './user/subscriptions-menu/subscription-activated/subscription-activated.component';
import { MapRedirectModalComponent } from './map-redirect-modal/map-redirect-modal.component';
import { SubscriptionConfirmationModalComponent } from './user/subscriptions-menu/subscription-confirmation-modal/subscription-confirmation-modal.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { ResetPasswordComponent } from './forgot-password-page/reset-password/reset-password.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SharedModule } from '../shared/shared.module';
import { SubscriptionRenewalModalComponent } from './user/subscriptions-menu/subscription-confirmation-modal/subscription-renewal-modal/subscription-renewal-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'password-recovery',
    component: ForgotPasswordPageComponent,
  },
  {
    path: 'user/password/reset',
    component: ResetPasswordComponent,
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
  },
  {
    path: 'sign-up/individual',
    component: RegisterIndividualComponent,
  },
  {
    path: 'sign-up/enterprise',
    component: RegisterIndividualComponent,
  },
  {
    path: 'sign-up/success',
    component: RegistrationSuccessWindowComponent,
  },
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: '',
        redirectTo: 'account-settings',
        pathMatch: 'full',
      },
      {
        path: 'my-searches',
        component: MySearchesComponent,
      },
      {
        path: 'subscription',
        component: SubscriptionsMenuComponent,
      },
      {
        path: 'account-settings',
        component: AccountSettingsFormComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
];

//prettier-ignore
@NgModule({
  declarations: [
    UserComponent,
    SignUpComponent,
    SignInComponent,
    RegisterIndividualComponent,
    MySearchesComponent,
    AccountSettingsFormComponent,
    SubscriptionsMenuComponent,
    PlanWithDescriptionComponent,
    RegistrationSuccessWindowComponent,
    SubscriptionActivatedComponent,
    MapRedirectModalComponent,
    SubscriptionConfirmationModalComponent,
    ForgotPasswordPageComponent,
    ResetPasswordComponent,
    SubscriptionRenewalModalComponent
  ],
  exports: [UserComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    FormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    SharedModule,
    MatExpansionModule,
  ],
})
export class UserModule {
}
