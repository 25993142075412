import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  DeviceOrientationService,
  PORTRAIT,
} from '../../services/device-orientation.service';
import { Subscription } from 'rxjs';

export interface NotificationModalProps {
  title: string;
  text: string;
  closable: boolean;
}

@Component({
  selector: 'app-switch-to-portrait-modal',
  templateUrl: './switch-to-portrait.component.html',
  styleUrls: ['./switch-to-portrait.component.scss',
  '../../../app.component.scss']
})
export class SwitchToPortraitComponent implements OnInit {

  private orientationSubscription!: Subscription;

  constructor(private dialogRef: MatDialogRef<SwitchToPortraitComponent>,
              private orientationService: DeviceOrientationService,
              @Inject(MAT_DIALOG_DATA) public data: NotificationModalProps) { }

  ngOnInit(): void {
    this.orientationSubscription = this.orientationService.orientation.subscribe(orientation => {
      if (orientation === PORTRAIT) {
        this.orientationSubscription.unsubscribe();
        this.dialogRef.close();
      }
    });
  }
}
