import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { NotificationModalProps } from '../switch-to-portrait-modal/switch-to-portrait.component';

@Component({
  selector: 'app-switch-to-desktop-modal',
  templateUrl: './switch-to-desktop-modal.component.html',
  styleUrls: [
    './switch-to-desktop-modal.component.scss',
    '../../../app.component.scss',
  ],
})
export class SwitchToDesktopModalComponent implements OnInit, AfterViewInit {
  constructor(
    private dialogRef: MatDialogRef<SwitchToDesktopModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotificationModalProps
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const modalContainer = document.querySelector('cdk-overlay-pane');
    //@ts-ignore
    modalContainer!.style.maxWidth = '100vw';
  }

  public close(): void {
    if (this.data.closable) {
      this.dialogRef.close();
    }
  }
}
