import { Component, Inject, OnInit } from '@angular/core';
import { SubscriptionsService } from '../../../../subscriptions.service';
import { ToastrService } from 'ngx-toastr';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AuthenticationService } from '../../../../authentication.service';

type ModalData = {
  title: string;
  subtitle: string;
  text: string;
};

@Component({
  selector: 'app-subscription-renewal-modal',
  templateUrl: './subscription-renewal-modal.component.html',
  styleUrls: ['./subscription-renewal-modal.component.scss',
  '../../../../../app.component.scss']
})
export class SubscriptionRenewalModalComponent implements OnInit {

  private isAutoRenewable!: boolean;

  public currentModalData!: ModalData

  private readonly modalData = {
    cancelRenewal: {
      title: 'Cancel subscription auto-renewal',
      subtitle: 'Are you sure you want to cancel the subscription auto-renewal?',
      text: 'After canceling subscription auto-renewal, you can still use it until the current period ends. Your subscription won\'t renew afterward, and you won\'t be charged.'
    },
    resumeRenewal: {
      title: 'Resume subscription auto-renewal',
      subtitle: 'Are you sure you want to resume the subscription auto-renewal?',
      text: 'After renewing subscription auto-renewal, you can continue enjoying its benefits seamlessly. Your subscription will renew automatically for the next period.'
    }
  }

  constructor(private dialogRef: MatDialogRef<SubscriptionRenewalModalComponent>,
              private subscriptionsService: SubscriptionsService,
              private toast: ToastrService,
              private authService: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isAutoRenewable = data.isAutoRenewable
  }

  ngOnInit(): void {
    this.currentModalData = this.isAutoRenewable ? this.modalData.cancelRenewal : this.modalData.resumeRenewal
  }

  public handleRenewalChange(): void {
    this.subscriptionsService.updateSubscriptionAutoRenewal(this.isAutoRenewable)
      .subscribe({
        next: () => {
          this.toast.success(this.isAutoRenewable ? 'Auto-renewal successfully canceled' : 'Auto-renewal successfully resumed')
          this.dialogRef.close()
          setTimeout(() => {
            this.authService.refreshUserInfo()
          }, 1_000)
        },
        error: () => {
          this.toast.error('Failed to update auto-renewal')
          this.dialogRef.close()
          setTimeout(() => {
            this.authService.refreshUserInfo()
          }, 1_000)
        },
      })
  }

  public handleClose(): void {
    this.dialogRef.close()
  }
}
