import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TRIGGER_REFRESH_USER_DATA } from '../subscriptions-menu.component';

@Component({
  selector: 'app-subscription-activated',
  templateUrl: './subscription-activated.component.html',
  styleUrls: ['./subscription-activated.component.scss'],
})
export class SubscriptionActivatedComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SubscriptionActivatedComponent>) {}

  ngOnInit(): void {
    localStorage.setItem(TRIGGER_REFRESH_USER_DATA, 'true');
  }

  public handleClose(): void {
    this.dialogRef.close();
  }
}
