import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  LayerStoreService,
  STATE_LEVEL_LAYER,
} from '../../../../mapbox/services/layer-store.service';
import { IconRegistryService } from '../../../../../shared/services/icon-registry.service';
import { LayersDataService } from '../layers-data.service';
import { ColorscaleService } from '../../../../mapbox/visualization/colorscale/colorscale.service';
import { UserAccessService } from '../../../../../user/access/user-access.service';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import {
  ChartDataByYear,
  FeatureStatus,
  PopulationService,
} from '../population-menu/population.service';
import { Plotly } from 'angular-plotly.js/lib/plotly.interface';
import { POIsFeatureStatus, PoisService } from '../pois/pois.service';
import { RoadDensityService } from '../road-density/road-density.service';
import { TargetAudienceService } from '../target-audience/target-audience.service';
import { HealthWealthWiseService } from '../health-wealth-wise/health-wealth-wise.service';
import { IncomeDistributionService } from '../wealth-menu/income-distribution.service';
import { HomeownershipService } from '../homeownership/homeownership.service';
import { EducationService } from '../education/education.service';
import { HealthService } from '../health/health.service';
import { PhysicalEnvironmentService } from '../physical-environment/physical-environment.service';
import { SelectedCellService } from '../../../../mapbox/services/selected-cell.service';
import {
  CRIME_GROUP_TITLE,
  EDUCATION_GROUP_TITLE,
  HEALTH_GROUP_TITLE,
  HOUSING_GROUP_TITLE,
  HWW_GROUP_TITLE,
  LayersMenuService,
  PHYSICAL_ENVIRONMENT_GROUP_TITLE,
  POI_GROUP_TITLE,
  POPULATION_GROUP_TITLE,
  ROAD_DENSITY_GROUP_TITLE,
  TARGET_AUDIENCE_GROUP_TITLE,
  WEALTH_GROUP_TITLE,
} from '../layers-menu.service';
import {
  CHARTS_GROUP,
  MAPS_GROUP,
  MenuGroupType,
  PLACES_GROUP,
} from '../../../../map.component';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { CrimeService } from '../crime/crime.service';
import { MapBoxService } from '../../../../mapbox/mapbox.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../../user/authentication.service';
import { ModalService } from '../../../../../shared/services/modal.service';
import { ROUTER_NAVIGATE } from '../../../../../user/map-redirect-modal/map-redirect-modal-text';
import { hwwFeatures } from '../../../../../shared/types/feature-data-type';

export interface MenuGroupData {
  title: string;
  featureStatus: FeatureStatus | null;
  e2eName?: string;
  disclaimer?: MenuGroupDisclaimer;
  scope?: {
    default?: FeatureScope;
    scopeDependentLayers?: string[];
  };

  tooltip?: {
    text: string;
    routerLink: string;
  };

  data: {
    mapColor: MapColorControlBlock[] | null;
    charts: ChartControlBlock[] | null;
    places: PointsControlBlock[] | ChartControlBlock[] | null;
  };
}

export type MenuGroupDisclaimer = {
  text: string;
  visibleOnLevels?: string[];
  visibleOnZoom?: {
    min?: number;
    max?: number;
  };
}

export interface MapColorControlBlock {
  featureName: string,
  featureConst: string,
  e2eName?: string,
  disclaimer?: {
    text: string,
    visibleOnLevels?: string[],
  },
  accessibleOnLevels?: string[],
  tooltip: {
    headerText: string,
    text: string,
  } | null
}

export interface ChartControlBlock {
  toggleTitle: string,
  chartName: string,
  // used for automated testing element navigation
  e2eName?: string,
  plotlyChart: {
    data: Plotly.Data,
    layout: Plotly.Layout,
  } | null,
  yearVariable: string | null,
  featureConst: string,
  containsChartDataVariable: string,
  accessibleYears: number[] | null,
  chartStyle?: { [p: string]: any },
  comingSoon?: boolean,
  customText?: string,
  disclaimer?: {
    text: string,
    visibleOnLevels?: string[],
  },
  chartsByYears?: ChartDataByYear,
  tooltip: {
    headerText: string,
    text: string,
  } | null
}

export interface PointsControlBlock {
  featureName: string,
  featureConst: string,
  e2eName?: string,
  disclaimer?: {
    text: string,
    visibleOnLevels?: string[],
    visibleOnZoom?: {
      min?: number,
      max?: number,
    }
  },
  accessibleOnLevels?: string[],
  tooltip: {
    headerText: string,
    text: string,
  } | null
}

export enum FeatureScope {
  BY_COUNTRY = '_by_country',
  BY_STATE = '_by_state'
}

@Component({
  selector: 'app-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss',
    '../../../../../app.component.scss',
  ],
  //TODO Implement OnPush
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuGroupComponent implements OnInit, OnDestroy {
  @Input() groupData!: MenuGroupData
  @Input() form!: FormGroup;
  @Input() isPaidPlan!: boolean | null

  @Input() activeMenuGroup!: MenuGroupType

  private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Looks stupid, but i didn't find better solution to call fillChartsData separately,
  // general calls leads to too many requests
  private groupNameToServiceNameMap = new Map<string, string>([
    [POPULATION_GROUP_TITLE, 'ageSexService'],
    [HWW_GROUP_TITLE, 'hwwService'],
    [WEALTH_GROUP_TITLE, 'incomeDistributionService'],
    [TARGET_AUDIENCE_GROUP_TITLE, 'targetAudienceService'],
    [HOUSING_GROUP_TITLE, 'homeownershipService'],
    [EDUCATION_GROUP_TITLE, 'educationService'],
    [HEALTH_GROUP_TITLE, 'healthService'],
    [ROAD_DENSITY_GROUP_TITLE, 'roadDensityService'],
    [POI_GROUP_TITLE, 'POIsService'],
    [CRIME_GROUP_TITLE, 'crimeService'],
    [PHYSICAL_ENVIRONMENT_GROUP_TITLE, 'physicalEnvironmentService'],
  ]);
  public isFeatureVisible = false;

  public wasYearSelected: boolean = false

  public chartFeatureList: string[] = []
  public placesFeatureList: string[] = []
  public mapColorFeatureList: string[] = []

  public toggleMap: Map<string, FormControl> = new Map<string, FormControl<boolean>>();
  public placesToggleMap: Map<string, FormControl> = new Map<string, FormControl<boolean>>();

  public readonly FeatureScope = FeatureScope
  public selectedScope!: FeatureScope

  private relatedServiceName!: string

  constructor(public layerStore: LayerStoreService,
              private layersMenuService: LayersMenuService,
              private iconRegistry: IconRegistryService,
              private layerDataService: LayersDataService,
              public colorScaleService: ColorscaleService,
              public accessService: UserAccessService,
              private mapboxService: MapBoxService,
              private selectedCellService: SelectedCellService,
              private ageSexService: PopulationService,
              private roadDensityService: RoadDensityService,
              private targetAudienceService: TargetAudienceService,
              private hwwService: HealthWealthWiseService,
              private incomeDistributionService: IncomeDistributionService,
              private homeownershipService: HomeownershipService,
              private educationService: EducationService,
              private healthService: HealthService,
              private POIsService: PoisService,
              private crimeService: CrimeService,
              private physicalEnvironmentService: PhysicalEnvironmentService,
              private modalService: ModalService,
              private router: Router,
              public authService: AuthenticationService,
              private cdr: ChangeDetectorRef
  ) {
    this.iconRegistry.initRightMenuIcons();
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.handleInputData()

    if (this.groupData.scope) {
      this.selectedScope = this.groupData.scope.default!;
    }

    this.isFeatureVisible = this.layerDataService.getIsGroupOpened(this.groupData.title)
  }

  ngOnDestroy() {
    this.destroy$.emit(true);
    this.destroy$.complete();
  }

  public onActiveLayerToggle(layer: string): void {
    this.form.get('activeLayer')!.setValue(layer);

    if (this.layerStore.activeLayer.value !== layer) {
      this.layerDataService.handleOpenedGroup(this.groupData.title, true)
      this.layerStore.activeLayer.next(layer);
    }
  }

  public handleRadioButtonClick(): void {
    if (!this.accessService.checkAccessIfUnauthorized()) {
      return;
    }
  }

  public handleInaccessibleFeatureClick(feature: string, featureName: string): void {
    if (!this.accessService.userHasAccess(feature)) {
      const hasAccessToken = this.authService.hasAccessToken()
      this.modalService.openModal({
        headerText: featureName,
        mainText: (hasAccessToken ? 'Subscribe' : 'Sign in' ) + ' to access this parameter',
        buttonText: hasAccessToken ? 'To subscriptions' : 'To sign in',
        URLtoGo: hasAccessToken ? 'user/subscription'  : 'sign-in',
        actionType: ROUTER_NAVIGATE
      })
      // this.router.navigate([this.authService.hasAccessToken() ? 'user/subscription'  : 'sign-in'])
    }
  }

  public handleSubscriptions(): void {
    this.accessService.isUserPlanUpdated
      .pipe(takeUntil(this.destroy$))
      .subscribe(updated => {
        if (updated) {
          this.layersMenuService.reassignTooltipText(this.groupData)

          // Force change detection to rerender menu as it could stuck after login/logout
          this.cdr.detectChanges()
        }
      })

    this.layerStore.activeLayer
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe((activeLayer) => {
        this.isFeatureVisible = this.mapColorFeatureList.includes(activeLayer)
          || hwwFeatures.includes(activeLayer) && this.groupData.title === HWW_GROUP_TITLE

        if (this.isFeatureVisible) {
          this.layerDataService.handleOpenedGroup(this.groupData.title, true)
        }
      });
  }

  private updateActiveTogglesArray(toggles: { [key: string]: boolean }) {
    Object.entries(toggles).forEach(([key, value]) => {
      if (value && !(this.groupData.featureStatus! as POIsFeatureStatus).activePoisCharts!.includes(key)) {
        (this.groupData.featureStatus! as POIsFeatureStatus).activePoisCharts!.push(key);
      } else if (!value) {
        const index = (this.groupData.featureStatus! as POIsFeatureStatus).activePoisCharts!.indexOf(key);
        if (index > -1) {
          (this.groupData.featureStatus! as POIsFeatureStatus).activePoisCharts!.splice(index, 1);
        }
      }
    });

    this.POIsService.handlePoisOnMap()
    this.POIsService.handlePoiLayerVisibility()
  }

  public onSelectedYearChange(data: { year: number, usedFor: string }): void {
    if (!this.accessService.checkAccessIfUnauthorized()) {
      return;
    }

    this.wasYearSelected = true
    // @ts-ignore
    this.groupData.featureStatus[data.usedFor] = data.year

    // Age/sex has it's own structure and comes will all available years at once
    if (data.usedFor !== 'ageSexYear') {
      this.handleFillChartsData()
    }
  }

  public toggleGroup(): void {
    this.isFeatureVisible = !this.isFeatureVisible
    this.layerDataService.handleOpenedGroup(this.groupData.title, this.isFeatureVisible)

    if (this.isFeatureVisible) {
      this.handleFillChartsData()
    }
  }

  private openDefaultGroup(): void {
    this.layerDataService.handleOpenedGroup(this.groupData.title, this.mapColorFeatureList.includes(this.layerStore.activeLayer.value))
    this.isFeatureVisible = this.layerDataService.getIsGroupOpened(this.groupData.title)
  }

  private handleInputData(): void {
    this.relatedServiceName = this.groupNameToServiceNameMap.get(this.groupData.title)!;


    if (this.groupData.data.charts) {
      this.groupData.data.charts.forEach(chart => {
        this.toggleMap.set(chart.featureConst, new FormControl(false))
      });

      this.chartFeatureList = Object.values(this.groupData.data.charts)
        .map(({featureConst}) => featureConst);
    }

    if (this.groupData.data.places) {
      this.groupData.data.places.forEach(place => {
        this.placesToggleMap.set(place.featureConst, new FormControl(false))
      });

      this.placesFeatureList = Object.values(this.groupData.data.places)
        .map(({featureConst}) => featureConst);
    }

    if (this.groupData.data.mapColor) {
      this.mapColorFeatureList = Object.values(this.groupData.data.mapColor)
        .map(({featureConst}) => featureConst);
    }

    // For poi group we have to update current state of active toggles in the service
    if (this.groupData.data.places && this.groupData.title === POI_GROUP_TITLE) {
      this.groupData.data.places.forEach(chart => {
        this.toggleMap.set(chart.featureConst, new FormControl(false))
      });

      this.toggleMap.forEach((control, key) => {
        if (control) {
          control.valueChanges.subscribe((value) => {

            this.updateActiveTogglesArray({[key]: value});
          });
        }
      });
    }

    this.openDefaultGroup()
  }

  private handleFillChartsData(): void {
    if (this.selectedCellService.isCellSelected()) {
      if (!this.accessService.checkAccessIfUnauthorized()) {
        return;
      }

      if ((this as any)[this.relatedServiceName]?.fillChartsData) {
        (this as any)[this.relatedServiceName].fillChartsData(this.selectedCellService.getSelectedFeature())
      }
    }
  }

  public onScopeToggle(scope: FeatureScope): void {
    if (!this.accessService.checkAccessIfUnauthorized()) {
      return;
    }

    const acceptableLayers = this.groupData.scope!.scopeDependentLayers || [];

    this.selectedScope = scope;

    const currentLayer = this.form.get('activeLayer')?.value;

    if (acceptableLayers.some(layer => currentLayer!.startsWith(layer))) {
      const baseLayer = acceptableLayers.find(layer => currentLayer!.startsWith(layer));
      const newActiveLayer = baseLayer + this.selectedScope;

      this.form.get('activeLayer')!.setValue(newActiveLayer);

      this.layerStore.activeLayer.next(newActiveLayer);
    }
  }

  public handlePointsToggleChange(event: MatSlideToggleChange): void {
    if (!this.accessService.checkAccessIfUnauthorized()) {
      return;
    }

    if ((this as any)[this.relatedServiceName].setPointsLayerVisibility) {
      (this as any)[this.relatedServiceName].setPointsLayerVisibility(event.checked)
    }
  }

  public identifyByConst(index: number, item: any): string {
    return item.featureConst;
  }

  protected readonly STATE_LEVEL_LAYER = STATE_LEVEL_LAYER;
  protected readonly MAPS_GROUP = MAPS_GROUP;
  protected readonly CHARTS_GROUP = CHARTS_GROUP;
  protected readonly PLACES_GROUP = PLACES_GROUP;
}
