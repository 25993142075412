import { Injectable } from '@angular/core';
import { HWW_GROUP_TITLE, LayersMenuService } from '../layers-menu.service';
import { BehaviorSubject } from 'rxjs';
import {
  MenuGroupData,
  MenuGroupDisclaimer,
} from '../menu-group/menu-group.component';
import { hwwFeatures } from '../../../../../shared/types/feature-data-type';

export interface FeatureFilter {
  featureConst: string;
  // Actual values in between which we are coloring map
  min: number | undefined;
  max: number | undefined;
  disclaimer?: MenuGroupDisclaimer;
  accessibleOnLevels?: string[];
  // Limits are defined as bottom and top borders for actual values
  limits: {
    min: number | undefined;
    max: number | undefined;
  };
}

export type FeatureWithAccessibleLevels = {
  featureConst: string,
  accessibleOnLevels?: string[]
}

@Injectable({
  providedIn: 'root',
})
export class FiltersMenuService {
  public featuresList!: {
    groupTitle: string | undefined,
    disclaimer?: MenuGroupDisclaimer,
    features: FeatureWithAccessibleLevels[],
  }[];
  public activeFilters: FeatureFilter[] = []

  // Subscribe to rerender map on filter changes
  public updateFiltersSubject: BehaviorSubject<any> = new BehaviorSubject<any>(true)

  constructor(private layersMenuService: LayersMenuService) {}

  public parseFeaturesIntoDropdown(): void {
    this.featuresList = this.layersMenuService.groupsData
      .filter((group: MenuGroupData) => group.data.mapColor)
      .map((group: MenuGroupData) => {
        return {
          groupTitle: group.title,
          disclaimer: group.disclaimer,
          features: this.getAccessibleFeatures(group)
        };
      });
  }

  public addFilter(feature: string): void {
    this.activeFilters.push({
      min: undefined,
      max: undefined,
      featureConst: feature,
      limits: {
        min: undefined,
        max: undefined
      }
    })

    this.updateFilters()
  }

  public removeFilter(feature: string): void {
    this.activeFilters = this.activeFilters.filter(el => el.featureConst !== feature)

    this.parseFeaturesIntoDropdown()
    this.updateFilters()
  }

  public updateFilters(): void {
    this.updateFiltersSubject.next(!this.updateFiltersSubject.value)
  }

  private getAccessibleFeatures(group: MenuGroupData): FeatureWithAccessibleLevels[] {
    return group.title === HWW_GROUP_TITLE ? this.getHwwFeatures().map(el => {
      return {
        featureConst: el
      }
    }) : group.data.mapColor!.map(el => {
      return {
        featureConst: el.featureConst,
        accessibleOnLevels: el.accessibleOnLevels
      }
    })
  }

  private getHwwFeatures(): string[] {
    return hwwFeatures.filter(el => {
      if (el.split('_').includes('state') || el.split('_').includes('country')) {
        return true
      }
      return false
    })
  }
}
