import { Component, Inject, OnInit } from '@angular/core';
import { FavoritesService } from '../favorites.service';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-favorite-personalization-modal',
  templateUrl: './favorite-personalization-modal.component.html',
  styleUrls: [
    './favorite-personalization-modal.component.scss',
    '../../../app.component.scss',
  ],
})
export class FavoritePersonalizationModalComponent implements OnInit {
  public cellTitle!: string;
  public cellDescription!: string;

  constructor(
    private favoritesService: FavoritesService,
    private dialogRef: MatDialogRef<FavoritePersonalizationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string; title: string }
  ) {}

  ngOnInit(): void {
    this.cellTitle = this.data.title;
  }

  public requestCreateFavoriteCell(): void {
    this.favoritesService.createFavoriteCell(
      this.data.id,
      this.cellDescription,
      this.cellTitle
    );
    this.dialogRef.close();
  }
}
