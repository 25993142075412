import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { IconRegistryService } from '../shared/services/icon-registry.service';
import { Industries } from '../shared/util/industries';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MapBoxService } from './mapbox/mapbox.service';
import {
  LayerStoreService,
  pointsMinZoom,
  STATE_LEVEL_LAYER,
} from './mapbox/services/layer-store.service';
import { BreakpointObserverService } from '../shared/services/breakpoint-observer.service';
import { LayersMenuService } from './menu/right-menu/layers-menu/layers-menu.service';
import {
  GUIDE_COMPLETED,
  PopupHintComponent,
} from './popup-hint/popup-hint.component';
import {
  CHARTS,
  getPopupPosition,
  LENSES,
  userTips,
} from './popup-hint/hint-utility';
import { PlotlyViaCDNModule } from 'angular-plotly.js';
import {
  FEATURES_CLICKED,
  UserAccessService,
} from '../user/access/user-access.service';
import { UserAgentService } from '../shared/services/user-agent.service';
import { Meta, Title } from '@angular/platform-browser';
import { mapPageTags } from '../shared/util/seo-tags';
import { ENTERPRISE } from '../user/authentication.service';
import { SubscriptionPlans } from '../user/user/user.model';
import { LngLatLike } from 'mapbox-gl';

export interface UserTip {
  title: string;
  cssClass: string;
  textContent: string;
  closable: boolean;
  mobile?: boolean;
  onClose?: (isUnauthorized: boolean) => void;
}

export const MAPS_GROUP = 'MAPS_GROUP'
export const CHARTS_GROUP = 'CHARTS_GROUP'
export const PLACES_GROUP = 'PLACES_GROUP'
export const FILTERS_GROUP = 'FILTERS_GROUP'

export type MenuGroupType = typeof MAPS_GROUP | typeof CHARTS_GROUP | typeof PLACES_GROUP | typeof FILTERS_GROUP

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit {
  public isMobile: boolean = false

  public mobileToolsOpened: boolean = false
  public showToolsMobile: boolean = false

  public activeMenuGroup: MenuGroupType = MAPS_GROUP

  private userTipIndex: number = 0

  @ViewChild(MatSidenav)
  rightMenu!: MatSidenav;

  @Output() readonly showSearchInput = new EventEmitter<boolean>();

  industries!: any;

  public isSearchInputActive: boolean = false;

  public isFavoritesVisible: boolean = false

  public $isLensesGuideOpened: Subject<boolean> = new Subject()

  constructor(
    private iconRegistry: IconRegistryService,
    private popupRef: MatDialog,
    private mapboxService: MapBoxService,
    private breakpointObserverService: BreakpointObserverService,
    private el: ElementRef,
    private userAgent: UserAgentService,
    public layerStore: LayerStoreService,
    public menuService: LayersMenuService,
    public accessService: UserAccessService,
    private meta: Meta,
    private title: Title
  ) {
    this.iconRegistry.initMapIcons();
    this.industries = Industries;
  }

  ngOnInit(): void {
    this.title.setTitle(mapPageTags.title)
    this.meta.addTags(mapPageTags.tags)

    this.breakpointObserverService.isMobile.subscribe(mobile => {
      if (mobile) {
        this.isMobile = true

        this.showToolsMobile = true
      } else {
        this.isMobile = false
        this.showToolsMobile = false

        setTimeout(() => {
          this.toggleRightMenu();
        })
      }
    })
  }

  ngAfterViewInit() {
    if (!localStorage.getItem(GUIDE_COMPLETED) && !this.userAgent.isUserAgentIos()) {
      // Workaround for IOs Safari bug, where simply afterViewInit is not enough to render user tips after first correctly
      setTimeout(() => {
        this.handleUserTips();
        // Add timeout as right menu does slide from right side upon opening, meaning that
      }, 500)
    }
  }

  public handleChartsGroupClick() {
    this.loadPlotly()
  }

  private loadPlotly() {
      if(this.isPlotlyLoaded()) {
        return;
      }
      PlotlyViaCDNModule.setPlotlyVersion('2.18.2');
      PlotlyViaCDNModule.setPlotlyBundle('finance');

    this.checkPlotlyLoaded().subscribe(isLoaded => {
     if (!isLoaded) {
        console.error('Failed to load Plotly, retrying...');
        setTimeout(() => this.loadPlotly(), 5000);
      }
    });
  }

  private isPlotlyLoaded(): boolean {
    //@ts-ignore
    return window['Plotly'];
  }

  private checkPlotlyLoaded(): Observable<boolean> {
    // Check if Plotly is loaded, if not we repeat loading and check
    return new Observable<boolean>(observer => {
      const check = () => {
        //@ts-ignore
        if (this.isPlotlyLoaded()) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      };
      setTimeout(check, 2000);
    });
  }

  public toggleRightMenu(): void {
    this.rightMenu.toggle().then((status: string) => {
      this.menuService.isMenuOpened = status !== 'close'
    });
  }

  public openRightMenu(): void {
    this.rightMenu.open().then((status: string) => {
      this.menuService.isMenuOpened = status !== 'close'
    })
  }

  public onSidenavClosed(): void {
    this.mapboxService.map.resize()
  }

  private handleUserTips(): void {
    if (this.userTipIndex >= userTips.length) {
      // All tips have been shown, set the flag in localStorage
      localStorage.setItem(GUIDE_COMPLETED, 'true');
      localStorage.setItem(FEATURES_CLICKED, '0');
      return;
    }

    const currentTip = userTips[this.userTipIndex];

    // If on mobile and the tip is not suitable for mobile, skip it
    if (this.isMobile && !currentTip.mobile) {
      this.userTipIndex++;
      this.handleUserTips();
      return;
    }

    this.showTip(currentTip);

    if (currentTip.title === CHARTS || currentTip.title === LENSES) {
      const center: LngLatLike = [-77.05332, 38.88812]
      this.mapboxService.map.flyTo({
        center: center,
        zoom: pointsMinZoom
      })

      if (currentTip.title === LENSES) {
        this.$isLensesGuideOpened.next(true)
      }

      this.handleDemoFeatures(currentTip, center)
    }
  }

  private showTip(tip: UserTip): void {
    const pointsTo = this.el.nativeElement.querySelector(`.${tip.cssClass}`);

    if (!pointsTo) return

    const dialogRef = this.popupRef.open(PopupHintComponent, {
      disableClose: false,
      maxWidth: '330px',
      maxHeight: '220px',
      width: '330px',
      position: getPopupPosition(tip, pointsTo, this.breakpointObserverService.isMobile.getValue()),
      data: {
        title: tip.title,
        cssClass: tip.cssClass,
        textContent: tip.textContent,
        closable: tip.closable
      }
    });

    if (tip.title === CHARTS) {
      this.activeMenuGroup = CHARTS_GROUP
    } else if (tip.title === LENSES) {
      this.activeMenuGroup = PLACES_GROUP
    } else {
      this.activeMenuGroup = MAPS_GROUP

      const center=  [-96, 37]
      this.mapboxService.map.flyTo({
        center: center as LngLatLike,
        zoom: 3
      })

      this.layerStore.handleLevelChange(STATE_LEVEL_LAYER)
    }

    dialogRef.afterClosed().subscribe(() => {
      if (tip.onClose) tip.onClose(this.accessService.getIsUnauthorized())
      if (localStorage.getItem(GUIDE_COMPLETED)) return
      if (tip.title === LENSES) {
        this.$isLensesGuideOpened.next(false)
      }

      this.userTipIndex++;

      this.handleUserTips();
    });
  }

  public handleSearchOpen(): void {
    // We have to check if the map is loaded before we can add geocoder as it's directly rely on map
    this.isSearchInputActive = this.mapboxService.map.loaded()
    if (this.isSearchInputActive) {
      this.isFavoritesVisible = false
    }
  }

  public handleSearchClose(): void {
    // Check if map is moving as mapbox doesnt remove geocoder while moving, which leads to UI glitch
    // like angular is sure that geocoder is closed and we render button again,
    // but in the background is still mapbox geocoder input field
    this.isSearchInputActive = this.mapboxService.map.isMoving()
  }

  public handleFavoritesOpen(): void {
    this.isFavoritesVisible = !this.mapboxService.map.isMoving()

    if (this.isFavoritesVisible) {
      this.isSearchInputActive = false
    }
  }

  public handleFavoritesClose(): void {
    this.isFavoritesVisible = false
  }

  private handleDemoFeatures(currentTip: UserTip, center: number[]): void {
    // Nest idle inside moveend to click center of map once we are zoomed-in and hexagons are loaded
    this.mapboxService.map.once('moveend', () => {
      this.mapboxService.map.once('idle', () => {
        this.mapboxService.simulateMapClick(center)
      })
    })

    // Use timeouts as we depend on side effects(mouse event click) here
    setTimeout(() => {
      if (currentTip.title === CHARTS) {

        // Simulate button click to trigger loadPlotly()
        const chartsGroupButton = document.querySelector('[data-cy="charts-menu-button"]')
        if (chartsGroupButton) {
          chartsGroupButton.dispatchEvent(new MouseEvent('click'))
        }

        const toggleButton = document.querySelector('[data-cy="toggle-age-sex-pyramid"]') as HTMLElement;

        if (toggleButton) {
          const inputElement = toggleButton.querySelector('input[type="checkbox"]') as HTMLElement;

          // Click exactly on inputEl inside matToggle as container click doesnt count
          if (inputElement) {
            inputElement.dispatchEvent(new MouseEvent('click'));
          }
        }
      } if (currentTip.title === LENSES) {
        const placesGroupButton = document.querySelector('[data-cy="places-menu-button"]');
        if (placesGroupButton) {
          placesGroupButton.dispatchEvent(new MouseEvent('click', { bubbles: true }));
        }

        const groupButton = document.querySelector('[data-cy="poiGroup"]');
        if (groupButton) {
          const button = groupButton.querySelector('button');
          if (button) {

            button.dispatchEvent(new MouseEvent('click', { bubbles: true }));
            setTimeout(() => {
              const toggleButton = document.querySelector(this.accessService.getIsUnauthorized() ? '[data-cy="toggle-convenience-poi"]' : '[data-cy="toggle-healthcare-poi"]');
              if (toggleButton) {

                const inputElement = toggleButton.querySelector('input[type="checkbox"]') as HTMLElement;
                if (inputElement) {
                  inputElement.dispatchEvent(new MouseEvent('click', { bubbles: true }));
                }
              }
            }, 0)
            this.$isLensesGuideOpened
              .pipe(take(1))
              .subscribe(isOpened => {
                if (!isOpened) button.dispatchEvent(new MouseEvent('click', { bubbles: true }));
              })
          }
        }
      }
    }, 0);
  }

  protected readonly MAPS_GROUP = MAPS_GROUP;
  protected readonly CHARTS_GROUP = CHARTS_GROUP;
  protected readonly PLACES_GROUP = PLACES_GROUP;
  protected readonly ENTERPRISE = ENTERPRISE;
  protected readonly SubscriptionPlans = SubscriptionPlans;
  protected readonly FILTERS_GROUP = FILTERS_GROUP;
}
