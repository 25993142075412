import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { MapModalData } from '../../../../../../user/map-redirect-modal/map-redirect-modal.component';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { AuthenticationService } from '../../../../../../user/authentication.service';

@Component({
  selector: 'app-feature-tooltip',
  templateUrl: './feature-tooltip.component.html',
  styleUrls: ['./feature-tooltip.component.scss'],
})
export class FeatureTooltipComponent implements OnInit {
  @Input() tooltipText!: string;
  @Input() tooltipRouterLink!: string | null;
  @Input() headerText!: string | undefined;
  @Input() fillColor: string = '#ccc';
  @Input() strokeColor: string = '#A3A3A3';
  @Input() opacity: number = 0.35;
  @Input() clickable: boolean = true;
  @Input() tooltipPosition = 'above' as TooltipPosition;

  @Input() isFeatureInaccessibleType: boolean = false;

  public hovered: boolean = false;

  tooltipModalData: MapModalData = {
    disableClose: false,
    disclaimerText:
      'Data and related materials represent an estimation produced from the data provided by a third party. These data are not guaranteed to be accurate, up to date or complete, and are provided "as is". Map AI makes no representations or warranties expressed or implied, with respect to the methods, algorithms, software, data or the related materials, including by way of example and not limitation, the implied warranties of merchantability and fitness for a particular purpose.',
  };

  constructor(
    private modalService: ModalService,
    public authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.tooltipText && this.headerText) {
      const parts = this.tooltipText.split('Source:');

      const mainText = parts[0].trim();
      const source = 'Source: ' + parts.slice(1).join('Source: ').trim();

      this.tooltipModalData.headerText = this.headerText;
      this.tooltipModalData.subHeaderText = mainText;
      this.tooltipModalData.mainText = source;
    }
  }

  public handleTooltipClick(event: Event): void {
    if (!this.tooltipRouterLink) {
      event.stopPropagation();
      this.modalService.openModal(this.tooltipModalData);
    }
    return;
  }
}
