import { Component, Inject, OnInit } from '@angular/core';
import { SubscriptionPlans } from '../../user.model';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { SubscriptionsService } from '../../../subscriptions.service';
import { AuthenticationService } from '../../../authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscription-confirmation-modal',
  templateUrl: './subscription-confirmation-modal.component.html',
  styleUrls: [
    './subscription-confirmation-modal.component.scss',
    '../../../user.shared.scss',
    '../../../../app.component.scss',
  ],
})
export class SubscriptionConfirmationModalComponent implements OnInit {
  public planName!: string;
  public price!: string | undefined;

  public isTOSChecked: boolean = false;

  public isRealtor: boolean = false;
  public realtorId!: string | undefined;
  public isRealtorIdInvalid: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SubscriptionConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { plan: SubscriptionPlans; isPeriodMonthly: boolean },
    private subscriptionService: SubscriptionsService,
    private authService: AuthenticationService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.planName = SubscriptionPlans.description[this.data.plan].planName;
    this.price = this.data.isPeriodMonthly
      ? SubscriptionPlans.description[this.data.plan].priceMonthly
      : SubscriptionPlans.description[this.data.plan].priceYearly;

    this.isRealtor =
      this.planName ===
      SubscriptionPlans.description[SubscriptionPlans.REALTOR].planName;
    this.realtorId =
      this.authService.userPersonalData.getValue()?.realtorsAssociationMemberId;
  }

  public handleToPayment(): void {
    if (this.isRealtor) {
      this.authService
        .updateUserInfo(
          {
            ...this.authService.userPersonalData.getValue(),
            realtorsAssociationMemberId: this.realtorId,
          }!
        )
        .subscribe((updatedUser) => {
          this.authService.userPersonalData.next(updatedUser.object);
        });
    }

    this.subscriptionService
      .handleSubscriptionPayment(this.data.plan, this.data.isPeriodMonthly)
      .subscribe((data) => {
        if (data.object.paymentUrl) {
          window.open(data.object.paymentUrl, '_blank');
        } else {
          this.toast.success('Subscription is updated');
          this.close();
        }
        setTimeout(() => {
          this.authService.refreshUserInfo();
        }, 1500);
      });
  }

  public handleRealtorIdChange(event: Event): void {
    //@ts-ignore
    const realtorId: string = event.target.value;
    this.isRealtorIdInvalid =
      realtorId.length > 9 || realtorId.length < 8 || !/^\d+$/.test(realtorId);
    this.realtorId = realtorId;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public isButtonAvailable(): boolean {
    if (this.isRealtor) {
      return (
        this.isTOSChecked &&
        !this.isRealtorIdInvalid &&
        !!this.realtorId &&
        this.realtorId.length > 0
      );
    } else {
      return this.isTOSChecked;
    }
  }
}
